<template>
    <Actions 
        :item="record"
        :page_name="pageName" />
</template>

<script>
import Actions from '@apps/vue2MeetingComponent/components/CardActions.vue'

export default {
    components: {
        Actions
    },
    props: {
        openTableSettings: {
            type: Function,
            default: () => {}
        },
        record: {
            type: Object,
            required: true
        },
        pageName: String,

    }
}
</script>